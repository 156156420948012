* {
    box-sizing: border-box;
  }
  
  .formbody {
    font-family: Lato;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
  }
  .formtitle {
   padding-bottom: 2.8rem;
   text-align: center !important;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 400px;
    min-width: 150px;
    min-height: 400px;
    padding: 20px 40px 40px 40px;
    border-radius: 6px;
    box-shadow: 0px 8px 36px #222;
    background-color: #fefefe;
    margin: 2rem;
  }
  
  form > h1 {
    display: flex;
    justify-content: center;
    font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 2em;
    font-weight: lighter;
    margin-top: 0.1em;
    color: #222;
    letter-spacing: 2px;
  }
  
  .info {
    padding-bottom: 1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  label{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.87);
    font-weight: lighter;
  }
  
  input {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: 100%;
  }
  input,
  select {
    padding: 12px 17px;
    border-radius: 5px;
    border: 1px solid #d6d1d5;
    margin-top: 5px;
  }
  .datepick {
    display: block;
    padding: 10px 10px;
    border-radius: 5px;
    border: 1px solid #d6d1d5;
    margin-top: 5px;
  }

  .MuiDayCalendar-monthContainer {
    max-width: 320px;

  }
  .MuiPickersLayout-root  {
    padding: 1.3rem;
  }
  .MuiDayCalendar-weekContainer {
    max-width: 36px;
    margin-left: 141px !important;
  }

 
  select {
    display: block;
    width: 100%;

  }

  input[type="checkbox"] {
    display: inline-block;
    width: auto;
    margin-top: 2em;
    margin-right: 10px;
  }
  
  button {
    min-width: 100%;
    cursor: pointer;
    margin-right: 0.25em;
    margin-top: 2.5em;
    padding: 0.938em;
    border: none;
    border-radius: 4px;
    background-color: #22223b;
    color: #fefefe;
  }
  button:hover {
    background-color: #4a4e69;
    color: #fefefe;
  }
  
  .error {
    color: #db2269;
    font-size: 0.5em;
    display: relative;
  }
  
  .submit {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

.checkboxlabel {
  display: block;
  text-align: center;
}

  @media (max-width: 768px) {
    form {
        display: flex;
        flex-direction: column;
        width: 90vh;
      }
        .formbody {
        font-family: Lato;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        margin-left: 0;
      }
      .formtitle {
       padding-bottom: 1.8rem;
      }
      
      form {
        display: flex;
        flex-direction: column;
        width: 250px;
        min-width: 150px;
        min-height: 400px;
        padding: 20px 40px 40px 40px;
        border-radius: 6px;
        box-shadow: 0px 8px 36px #222;
        background-color: #fefefe;
        margin: 2rem;
      }
  }
  